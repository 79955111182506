import React, { Component } from "react";
import $ from "jquery";

import ImageContainer from "./ImageContainer.js";
import LabelsContainer from "./LabelsContainer.tsx";
import { printLabel } from "../../containers/LabelSelector/LabelSelector.tsx";

import {HotKeys} from "react-hotkeys";
import { getFetch } from "../../utils/fetchUtils.js";
import { UserContext } from "../../context/userContext";
import { getDateTime } from "../../utils/dateUtils.js";

const AWS = require("aws-sdk");

// TODO: Remove access keys from Frontend
const s3 = new AWS.S3({
  accessKeyId: "AKIA4ZN2FXCSXF3DZJSD", secretAccessKey: "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", useAccelerateEndpoint: false, signatureVersion: "v4", region: "eu-central-1"
});

const map = {
  // 'nextImage': 'alt+enter',
  "fullPercent1": "alt+y",
  "fullPercent2": "alt+x",
};

const normalBgColor = "#fafafa";
const orangeBgColor = "#f4bd9f";

const isCorrect = (correct, answer, tolerance) => {
  if (correct.length !== answer.length)
    return false;
  const sorted_correct = [...correct].sort((a, b) => (a.id - b.id));
  const sorted_answer = [...answer].sort((a, b) => (a.id - b.id));

  for (const k in sorted_correct) {
    if (sorted_correct[k].id !== sorted_answer[k].id)
      return false;
    // if (sorted_correct[k].transformed !== sorted_answer[k].transformed)
    //   return false;
    if (Math.abs(sorted_correct[k].percentage - sorted_answer[k].percentage) > tolerance)
      return false;
  }
  return true;
};

const getTransformedString = (label, transformed) => {
  if (transformed === null)
    return "";
  if (transformed === true)
    return label.transformed;
  return label.untransformed;
};

const QuizResultsView = ({allLabels, curr_question, tolerance, hide}) => {
  if (!curr_question || !curr_question.data.answers)
    return <></>;
  const is_correct = isCorrect(curr_question.data.labels, curr_question.data.answers, tolerance);
  const bgColor = curr_question.data.weight >= 1000 ? orangeBgColor : normalBgColor;
  return <div
    style={{
      position: "absolute", width: "40%", height: "100%", left: "60%", backgroundColor: bgColor, zIndex: "101"
    }}>
    <div
      style={{
        position: "relative", width: "80%", margin: "5em auto"
      }}>
      <h1 style={{color: is_correct ? "green" : "red"}}>{is_correct ? "Correct!" : "Wrong..."}</h1>
      <h4>Your answer:</h4>
      {curr_question.data.answers.map((label) => <p key={`a-${label.id}`}>{`${printLabel(allLabels[label.id])} - ${label.percentage}% - [${getTransformedString(allLabels[label.id], label.transformed)}]`}</p>)}
      <h4>Correct answer:</h4>
      {curr_question.data.labels.map((label) => <p key={`c-${label.id}`}>{`${printLabel(allLabels[label.id])} - ${label.percentage}% - [${getTransformedString(allLabels[label.id], label.transformed)}]`}</p>)}
      <button onClick={hide} className="btn btn-primary backToNextImage">Next Image</button>
    </div>
  </div>;
};

const createQuizResults = (quiz, quiz_questions, mode) => {
  const results = {
    data: {
      start: quiz.start,
      end: getDateTime(),
      answers: []
    }
  };
  const answers = results.data.answers;
  let score = 0;
  for (const q in quiz_questions) {
    const question = quiz_questions[q];
    const answer = {
      answer_correct: isCorrect(question.data.labels, question.data.answers, quiz.data.tolerance),
      answer: [...question.data.answers],
      correct: [...question.data.labels]
    };
    if (mode === "quiz")
      answer.question_id = question.id;
    else if (mode === "level1exam")
      answer.trashevent_id = question.data.trashevent_id;

    if (answer.answer_correct)
      score++;
    answers.push(answer);
  }

  results.score = Math.round(score * 100 / quiz_questions.length);
  results.passing_mark = quiz.data.passing_mark;

  return results;
};

const NotificationStrip = ({children}) => <div
  style={{
    position: "absolute", width: "100%", height: "100%", zIndex: "105"
  }}>
  <div
    style={{
      position: "absolute", top: "30%", left: "50%", width: "50%", transform: "translate(-50%, -50%)", backgroundColor: "white", border: "1px solid gray", padding: "2em"
    }}>
    {children}
  </div>
</div>;

class TrainingQuiz extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);

    this.nextImage = this.nextImage.bind(this);
    this.getURL = this.getURL.bind(this);
    this.showResults = this.showResults.bind(this);
    this.getFilteredLabels = this.getFilteredLabels.bind(this);

    this.state = {
      currentIndex: 0,
      currentLabelIndex: 1,
      imageSwitching: false,
      viewMode: "L",
      quiz: {},
      quiz_questions: [],
      labels: [],
      currURL: null,
      showResults: false,
      mode: "quiz",
      notificationShow: false,
      notificationTitle: "",
      notificationMessage: ""
    };
  }

  componentDidMount() {
    if (!this.props.history.location.state) {
      this.props.history.push("/");
      return;
    }

    const quiz = this.props.history.location.state.quiz;
    quiz.start = getDateTime();
    const quiz_questions = this.props.history.location.state.quiz_questions;

    const prevURL = this.getURL(quiz_questions[this.state.currentIndex].data.previous_image_id);
    const currURL = this.getURL(quiz_questions[this.state.currentIndex].data.image_id);

    this.setState({
      quiz: quiz,
      quiz_questions: quiz_questions,
      labels: this.props.history.location.state.fullLabelData,
      prevURL: prevURL,
      currURL: currURL,
      mode: this.props.history.location.state.mode,
    });
  }

  showResults(ls) {
    const curr_question = this.state.quiz_questions[this.state.currentIndex];
    curr_question.data.answers = ls;
    this.setState({
      quiz_questions: this.state.quiz_questions,
      showResults: true
    });
  }

  async nextImage() {
    if (this.inputRef)
      this.inputRef.focus();

    const currIndex = this.state.currentIndex;
    const quiz_questions = this.state.quiz_questions;
    const batchCompleted = currIndex + 1 === quiz_questions.length;

    if (!batchCompleted) {
      this.setState({
        currentIndex: parseInt(currIndex) + 1,
        prevURL: this.getURL(quiz_questions[currIndex + 1].data.previous_image_id),
        currURL: this.getURL(quiz_questions[currIndex + 1].data.image_id),
      });
    }

    if (batchCompleted) {
      const results = createQuizResults(this.state.quiz, this.state.quiz_questions, this.state.mode);
      results.log_datetime = getDateTime();

      $(".success-checkmark").css("display", "block");
      $(".check-icon").show();

      setTimeout(() => {
        $(".check-icon").hide();
        $(".success-checkmark").css("display", "none");
      }, 1000);

      if (this.state.mode === "quiz")
        await getFetch(`/training/quiz/${this.state.quiz.id}/result`, {data: results, method: "POST"});
      else if (this.state.mode === "level1exam")
        await getFetch("/training/exam/result", {data: results, method: "POST"});


      let title = "";
      let message = "";
      if (this.state.mode === "quiz") {
        title = "Quiz done!";
        if (results.score >= results.passing_mark)
          message = `This quiz is now finished.\n\nYour score is ${results.score}%.\n\nCongratulations, you passed it!`;
        else
          message = `This quiz is now finished.\n\nYour score is ${results.score}%.\n\nUnfortunately, a minimum score of ${results.passing_mark}% is required to pass. You can retake quizzes you have not passed yet from the Home Page.`;
      }
      else if (this.state.mode === "level1exam") {
        title = "Exam done!";
        if (results.score >= results.passing_mark) {
          message = `The exam is now finished.\n\nYour score is ${results.score}%.\n\nCongratulations, you passed it!\n\nYou are now level 1 and will start labelling real pictures.`;
          const { user, setUser } = this.context;
          user.labellingInfo.level = 1;
          setUser(user);
        }
        else
          message = `The exam is now finished.\n\nYour score is ${results.score}%.\n\nUnfortunately, a minimum score of ${results.passing_mark}% is required to pass so you will need to take it again from the Home Page.`;
      }
      this.setState({
        notificationShow: true,
        notificationTitle: title,
        notificationMessage: message
      });
    }
  }

  getURL(image_key) {
    const params = {Bucket: "kitroengine-s3", Key: image_key};
    const url = s3.getSignedUrl("getObject", params);
    return url;
  }

  getFilteredLabels(curr_question) {
    if (!curr_question || !curr_question.data.answers)
      return null;
    return curr_question.data.answers.filter((an) => an.id in this.state.labels);
  }

  render() {
    const handlers = {
      // 'nextImage': this.labelComplete() ? (e) => {e.preventDefault(); this.nextImage(null, null);} :  (e) => {e.preventDefault(); console.log("Label incomplete");},
      "fullPercent1": (e) => { e.preventDefault(); document.getElementById("makeFullPercent:0") && document.getElementById("makeFullPercent:0").click(); },
      "fullPercent2": (e) => { e.preventDefault(); document.getElementById("makeFullPercent:1") && document.getElementById("makeFullPercent:1").click(); },
    };

    const curr_question = this.state.quiz_questions[this.state.currentIndex];
    if (curr_question) {
      if (curr_question.data.weight >= 1000) {
        // alert("Caution! The scale is indicating an abnormally high amount of waste thrown in at once! Please adjust the labels accordingly!")
        $("#root").css("background-color", orangeBgColor);
        $("#labelsContainerWindow").css("background-color", orangeBgColor);
      }
      else {
        $("#root").css("background-color", normalBgColor);
        $("#labelsContainerWindow").css("background-color", normalBgColor);
      }
    }

    const tolerance = this.state.quiz.data ? this.state.quiz.data.tolerance : 0;

    return (
      <>
        {this.state.notificationShow
          ? <NotificationStrip>
            <h1>{this.state.notificationTitle}</h1>
            <p>{this.state.notificationMessage}</p>
            <button className="btn btn-primary overviewButton buttonColorLabelling" onClick={() => { this.setState({notificationShow: false}); this.props.history.push("/"); }}>OK</button>
          </NotificationStrip>
          : ""}
        {this.state.showResults
          ? <QuizResultsView allLabels={this.state.labels} curr_question={curr_question} tolerance={tolerance} hide={() => { this.nextImage(); this.setState({showResults: false}); }} />
          : ""}
        <HotKeys keyMap={map} handlers={handlers}>
          <div className="animated fadeIn">
            <h1 style={{marginTop: "-15px"}}>{this.state.quiz.data ? this.state.quiz.data.name : ""} (question {this.state.currentIndex + 1}/{this.state.quiz_questions.length})</h1>
            <div
              style={{
                marginBottom: "5px", fontWeight: "bold", fontSize: "1rem", maxWidth: "64%"
              }}>
              {/* <span style={{marginRight: '18%'}}>{"Area: " + (this.state.batchData[this.state.imageData[this.state.currentIndex]] == undefined ? "" : this.state.batchData[this.state.imageData[this.state.currentIndex]].area)}</span> */}
              <span style={{marginRight: "18%"}}>{"Device ID: " + (curr_question ? curr_question.data.device_id : "")}</span>
              <span>{"Added Weight: " + (curr_question ? curr_question.data.weight : "") + "g"}</span>
            </div>
            <ImageContainer
              prevImage={this.state.prevURL || null}
              currImage={this.state.currURL || null}
            />
            <LabelsContainer
              nextImageFunction={this.showResults}
              nextButtonString={"Confirm Label"}
              currentLabel={this.getFilteredLabels(curr_question)}
              labels={this.state.labels}
              topDistance={159}
            />
          </div>
        </HotKeys>
      </>
    );
  }
}

export default TrainingQuiz;
